/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect } from "react";
import { API_BASEURL, HASURA_ENDPOINT_DEV } from "../config";
import { createClient, Provider, defaultExchanges } from "urql";
import { useCookies } from "react-cookie";
import ThemedSuspense from "../components/ThemedSuspense";
import { MdSignalWifiConnectedNoInternet0 } from "react-icons/md";
import {
  getObjectFromLocalStorage,
  saveObjectInLocalStorage,
} from "../utils/localStorage";
import axios from "axios";

export const AuthContext = createContext({});

function changeFavicon(newIconUrl, app_name) {
  if (newIconUrl) {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = newIconUrl;

    document.getElementsByTagName("head")[0].appendChild(link);
  }

  // Update tab title
  if (app_name) document.title = app_name;
}

function inializeClinet(token) {
  return createClient({
    url: `https://${HASURA_ENDPOINT_DEV}`,
    exchanges: [...defaultExchanges],
    requestPolicy: "network-only",
    fetchOptions: () => {
      return {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      };
    },
  });
}

let client;

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isOffline, setIsOffline] = useState(false);
  const [loading, setLoading] = useState(true);
  const [trailData, setTrialData] = useState({
    show: false,
    left: null,
  });

  // eslint-disable-next-line no-unused-vars
  const [_, setCookie, removeCookie] = useCookies(["accessToken"]);

  useEffect(() => {
    const auth = getObjectFromLocalStorage("authData");
    const token = auth?.token;
    if (token) {
      client = inializeClinet(token);

      validateUser(token)
        .then((result) => onVarified(result, token))
        .catch((error) => {
          console.error("[validate user]", error.message);

          if (error.message === "NETWORK_ERROR") {
            setIsOffline(true);
            setLoading(false);
          } else if (error.message === "FORBIDDEN") {
            logout();
          }
        });
    } else {
      setLoading(false);
    }
  }, []);

  const handleTrailAlert = (data) =>
    setTrialData((prev) => {
      return {
        ...prev,
        ...data,
      };
    });

  function checkInternetConnection() {
    if (navigator.onLine) {
      return true; // Online
    } else {
      return false; // Offline
    }
  }

  const onVarified = (user, token) => {
    console.log("user", user?.branding);
  
    saveObjectInLocalStorage("branding", {
      logo_url: user?.branding?.logo?.url,
      favicon_url: user?.branding?.logo_small?.url,
      app_name: user?.branding?.app_name,
      billing_url: user?.branding?.billing_url,
    });
 
    changeFavicon(user?.branding?.logo_small?.url, user?.branding?.app_name);
 
    const authData = getObjectFromLocalStorage("authData");
    saveObjectInLocalStorage("authData", {
      ...authData,
      packages: user?.packages,
    });
    // packages
    setUser({
      ...authData,
      ...user,
      packages: user?.packages,
      id: user?.userId ?? authData?.userId,
      logo_url: user?.branding?.logo?.url,
      favicon_url: user?.branding?.logo_small?.url,
      app_name: user?.branding?.app_name,
      billing_url: user?.branding?.billing_url,
    });
    setCookie("accessToken", token, {
      maxAge: 99999999,
    });

    const userFreeTrialTill = new Date(user?.free_trial_valid_till);

    const alerTime = getObjectFromLocalStorage("trial_alert_time");

    if (
      userFreeTrialTill > new Date() &&
      alerTime !== new Date().toLocaleDateString()
    ) {
      // calculate free trial left
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

      const freeTrialLeft = Math.round(
        Math.abs((userFreeTrialTill - new Date()) / oneDay)
      );

      handleTrailAlert({
        show: true,
        left: freeTrialLeft,
      });
    } else {
      handleTrailAlert({
        show: false,
        left: null,
      });
    }

    setLoading(false);
  };

  //  const checkAuth = async (auth) => {
  //   try {
  //     let currentVersion = chrome?.runtime?.getManifest()?.version;

  //     const res = await axios({
  //       url: `${BASE_APP_API_URL}/resources/verify-user`,
  //       headers: {
  //         Authorization: `Bearer ${auth.token}`,
  //       },
  //       method: 'POST',
  //       data: {
  //         // fb_userid: Number(fb_userid),
  //         ext_ver: currentVersion,
  //       },
  //     });

  //     const data = res?.data?.data;

  //     if (data) {
  //       // if (process.env.NODE_ENV !== 'development') {
  //       //   if (data?.ext_ver !== currentVersion) {
  //       //     return 'OUTDATED_VERSION';
  //       //   }
  //       // }

  //       await saveObjectInLocalStorage({
  //         authData: {
  //           ...auth,
  //           packages: data?.packages,
  //           vision_api_credits_left: data?.vision_api_credits_left,
  //         },
  //       });

  //       return data;
  //     } else {
  //       if (
  //         res?.data?.status === 'error' &&
  //         res?.data.message === 'No connected account found'
  //       ) {
  //         return 'NO_CONNECTED_FB_ACCONT_FOUND';
  //       }
  //       return 'ERROR';
  //     }
  //   } catch (error) {
  //     console.error('@[checkAuth]', error);
  //     if (error?.code === 'ERR_NETWORK') {
  //       return 'NETWORK_ERROR';
  //     } else if (error?.response?.status === 403) {
  //       return 'FORBIDDEN';
  //     } else {
  //       return 'ERROR';
  //     }
  //   }
  // };

  const login = (data, history) => {
    saveObjectInLocalStorage("authData", data);
    saveObjectInLocalStorage("isDraggable", true);
    client = inializeClinet(data.token);

    validateUser(data.token).then((result) => {
      onVarified(result, data.token);
      if (history) {
        history.push("/", {
          price_id: data?.price_id,
          ref_id: data?.ref_id,
        });
      }
    });
  };

  const logout = () => {
    removeCookie("accessToken");
    localStorage.removeItem("token");
    localStorage.removeItem("authData");
    localStorage.removeItem("team");
    setUser(null);
  };

  const validateUser = async (token) => {
    if (checkInternetConnection()) {
      //   try {
      //     const res = await axios({
      //       url: `${API_BASEURL}/resources/verify-user`,
      //       headers: {
      //         Authorization: `Bearer ${token}`,
      //       },
      //       method: "POST",
      //       data: {},
      //     });
      //     const result = await client.query(GetUserByPK, { id }).toPromise();
      //     if (result.error) throw new Error(result.error);
      //     const _user = result?.data?.user_by_pk;

      //     if (!_user?.reseller?.is_active || !_user?.is_active) {
      //       //TODO:: updat to personal team if requird need to be confirmed
      //       throw new Error("Access denied");
      //     }

      //     return _user;
      //   } catch (e) {
      //     console.error("[fetchUserDetails]", e);
      //   }
      // } else {
      //   throw new Error("NO_NETWORK");
      // }
      try {
        const res = await axios({
          url: `${API_BASEURL}/resources/verify-user`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: "POST",
          data: {
            // fb_userid: Number(fb_userid),
          },
        });

        const data = res?.data?.data;

        if (data) {
          return data;
        } else {
          if (
            res?.data?.status === "error" &&
            res?.data.message === "No connected account found"
          ) {
            return "NO_CONNECTED_FB_ACCONT_FOUND";
          }
          return "ERROR";
        }
      } catch (error) {
        console.error("@[checkAuth]", error);
        if (error?.code === "ERR_NETWORK") {
          return "NETWORK_ERROR";
        } else if (error?.response?.status === 403) {
          return "FORBIDDEN";
        } else {
          return "ERROR";
        }
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{ user, login, logout, handleTrailAlert, trailData }}
    >
      {loading ? (
        <ThemedSuspense />
      ) : isOffline ? (
        <div className="h-screen w-full flex items-center justify-center gap-1 flex-col bg-gray-100">
          <MdSignalWifiConnectedNoInternet0 className="h-12 w-12 text-gray-500" />
          <p className="text-gray-500 text-base">
            You are currently offline. Please check your internet connection and
            try again.
          </p>
        </div>
      ) : (
        <Provider value={client}>{children}</Provider>
      )}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
