/**
 * Save Object in Chrome's Local StorageArea
 * @param {*} key
 */
const getObjectFromLocalStorage = function (key) {
  return JSON.parse(window.localStorage.getItem(key));
};

/**
 * Save Object in Chrome's Local StorageArea
 * @param {*} key
 * @param {*} value
 */
const saveObjectInLocalStorage = function (key, value) {
  return window.localStorage.setItem(key, JSON.stringify(value));
};

export { saveObjectInLocalStorage, getObjectFromLocalStorage };
