import React from "react";

import { AiOutlineLoading3Quarters } from "react-icons/ai";

function ThemedSuspense() {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <AiOutlineLoading3Quarters  className="h-12 w-6  animate-spin text-teal-600"/>
    </div>
  );
}

export default ThemedSuspense;
