export const HASURA_ENDPOINT_DEV = "hasurav2.growthnodes.net/v1/graphql";
export const API_BASEURL = "https://api.growthnodes.net";
// export const API_BASEURL = "http://localhost:4004";
//
export const STRIPE_URLS = {
  customer_portal: `${API_BASEURL}/stripe/customer-portal`,
};

export const DEFAULT_RESELLER_ID = "423bd46c-a945-46f3-ba78-f4a85c68bbb1";

// paddle
export const SANDBOX_VENDOR_ID = 8102;
export const SANDBOX_VENDOR_AUTH_CODE =
  "b85d308467ccbe5102969269948206b8285829987d53dcbf8d";
export const VENDOR_ID = 156251;
export const VENDOR_AUTH_CODE =
  "6fae3e7cfecf91d5ea93a5883c1c6f80a6291e162e92465f4f";
export const PADDLE_BASE_URL = "https://vendors.paddle.com/api/2.0/";
export const PADDLE_SANDBOX_BASE_URL =
  "https://sandbox-vendors.paddle.com/api/2.0/";

// sendgrid
export const SENDGRID_BASE_URL = "https://api.sendgrid.com/v3/";
export const SENDGRID_API_KEY =
  "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";
export const SENDGRID_CONTACT_LISTS = {
  all: "386b24b0-a32f-40f8-9e68-88b65896d978",
  paid: "87b12ec7-d138-49b0-a3e4-6973f127f1ac",
  trial: "97c5a04a-68b2-4328-be55-b03884e62eec",
};

export const SENTRY_DSN =
  "https://257dce89b04f46f1805ab0571ca31fb0@o4504060423831552.ingest.sentry.io/4504060893724672";
