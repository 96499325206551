import React, { lazy, useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
// const Stripe = lazy(() => import("./pages/Stripe"));
// const CheckOut = lazy(() => import("./pages/Checkout"));
// const Onboarding = lazy(() => import("./pages/Onboarding"));

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = useContext(AuthContext);
  const packages = user?.packages;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (user) {

          //TODO::onboarding temp disable
          // const qus = user?.onboarding_questions;

          // const isOnboardDone =
          //   qus?.business_form_done &&
          //   qus?.role_form_done &&
          //   qus?.usecase_form_done;

          const path = rest?.location?.pathname;
          // if (!isOnboardDone && user?.plan_id) {
          //   return <Onboarding {...props} />;
          // } else
          // if (path.startsWith("/onboarding") && isOnboardDone) {
          if (path.startsWith("/onboarding")) {
            return <Redirect to="/" />;
          } else {
            const noAccess = false;
            // const noAccess = new Date(user?.crm_access_till) < new Date();

            if (path.startsWith("/sales/pipelines")) {
              const pipeline_limit =
                packages?.["PIPELINE"]?.details?.pipeline_limit;

              if (!pipeline_limit) {
                return <Redirect to="/settings/billing" />;
              }
            }

            if (path.startsWith("/sales/deals")) {
              const deals_limit = packages?.["DEALS"]?.details?.deals_limit;

              if (!deals_limit) {
                return <Redirect to="/settings/billing" />;
              }
            }

            if (path.startsWith("/sales/company")) {
              const company_limit =
                packages?.["COMPANY"]?.details?.company_limit;

              if (!company_limit) {
                return <Redirect to="/settings/billing" />;
              }
            }

            return <Component {...props} noAccess={noAccess} />;
          }
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
};

export default PrivateRoute;
