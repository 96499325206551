import React, { useState, useMemo, useEffect } from "react";
import {
  getObjectFromLocalStorage,
  saveObjectInLocalStorage,
} from "../utils/localStorage";

// create context
export const AccountContext = React.createContext();

export const AccountProvider = ({ children }) => {
  const [account, setAccount] = useState(null);

  const value = useMemo(
    () => ({
      account,
      setAccount,
    }),
    [account]
  );

  useEffect(() => {
    const token = getObjectFromLocalStorage("authData")?.token;
    if (token) {
      setAccount(getObjectFromLocalStorage("account") || null);
    }
  }, []);

  useEffect(() => {
    saveObjectInLocalStorage("account", account);
  }, [account]);

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
};
