/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from "react";
import { getObjectFromLocalStorage } from "../utils/localStorage";

// create context
export const SidebarContext = React.createContext();

export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [commonCensor, setCommonCensor] = useState(
    getObjectFromLocalStorage("censor") || false
  );
  const [isDraggable, setIsDraggable] = useState(
    getObjectFromLocalStorage("isDraggable") || false
  );

  function toggleSidebar() {
    setIsSidebarOpen(!isSidebarOpen);
  }

  function closeSidebar() {
    setIsSidebarOpen(false);
  }

  const value = useMemo(
    () => ({
      isSidebarOpen,
      toggleSidebar,
      closeSidebar,
      commonCensor,
      setCommonCensor,
      isDraggable,
      setIsDraggable,
    }),
    [isSidebarOpen, commonCensor, isDraggable]
  );

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};
