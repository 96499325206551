import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./assets/css/tailwind.output.css";
import App from "./App";
import { SidebarProvider } from "./context/SidebarContext";
import ThemedSuspense from "./components/ThemedSuspense";
import { Windmill } from "@windmill/react-ui";
import * as serviceWorker from "./serviceWorker";
import AuthProvider from "./context/AuthContext";
import { ToastProvider } from "react-toast-notifications";
import "./assets/css/index.css";

import myTheme from "./myTheme.js";
import { AccountProvider } from "./context/AccountContext";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { SENTRY_DSN } from "./config";
import { TeamProvider } from "./context/TeamContext";

process.env.NODE_ENV === "production" &&
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2,
  });



ReactDOM.render(
  <AuthProvider>
    <ToastProvider>
      <AccountProvider>
        <TeamProvider>
          <SidebarProvider>
            <Suspense fallback={<ThemedSuspense />}>
              <Windmill theme={myTheme}>
                <App />
              </Windmill>
            </Suspense>
          </SidebarProvider>
        </TeamProvider>
      </AccountProvider>
    </ToastProvider>
  </AuthProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
