/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useContext, useEffect } from "react";
import { httpClient } from "../httpClient";
import {
  getObjectFromLocalStorage,
  saveObjectInLocalStorage,
} from "../utils/localStorage";
import { RiUserLine } from "react-icons/ri";

import { useToasts } from "react-toast-notifications";
import { AuthContext } from "./AuthContext";
import { PERSONAL_TEAM } from "../utils";
import { useClient } from "urql";
import { GetTeamsAndUsers } from "../graphql/team";
import Modal from "react-responsive-modal";

// create context
export const TeamContext = React.createContext();

// create context provider
export const TeamProvider = ({ children }) => {
  const { addToast } = useToasts();
  const { login, user } = useContext(AuthContext);
  const [teamData, setTeamData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(PERSONAL_TEAM);
  const [isFetchTeamUsers, setIsFetchTeamUser] = useState(false);
  const [teamAndUsers, setTeamAndUsers] = useState({
    team: [],
    team_user: [],
  });

  const client = useClient();

  const getTeams = async () => {
    try {
      setIsFetchTeamUser(true);
      const result = await client.query(GetTeamsAndUsers).toPromise();

      if (result.error) throw new Error(result.error);

      setTeamAndUsers(result.data);
      setIsFetchTeamUser(false);
    } catch (e) {
      console.error("[get teams]", e);
      setIsFetchTeamUser(false);
    }
  };

  const onRowSelectStateChange = () => {};

  const getSingleTeam = async () => {
    const teamObj = getObjectFromLocalStorage("team") || PERSONAL_TEAM;

    setSelectedTeam(teamObj);

    if (teamObj?.name === "personal") return;
    setFetching(true);

    try {
      const resp = await httpClient("/team/get-members", {
        method: "POST",
        body: {
          team_id: teamObj?.id,
        },
      });

      if (resp?.status !== "ok") {
        addToast(resp?.message, {
          appearance: "error",
        });
        return resp;
      }
      setTeamData(resp?.data);
    } catch (error) {
      addToast(error?.message?.message || error?.message, {
        appearance: "error",
      });
      return error;
    } finally {
      setFetching(false);
    }
  };

  /* switch team request using swap token */
  const switchTeam = async (team) => {
    try {
      //if sekect a same team
      if (team?.id === selectedTeam?.id) return false;
      setIsSwitching(true);

      const resp = await httpClient("/team/swap-token", {
        method: "POST",
        body:
          team?.name !== "personal"
            ? {
                team_id: team?.id,
              }
            : {},
      });

      if (resp?.status !== "ok") {
        addToast(resp?.message?.message || resp?.message, {
          appearance: "error",
        });
        return false;
      }
      saveObjectInLocalStorage("authData", resp?.data);
      console.log("resp?.data", resp?.data);
      saveObjectInLocalStorage("team", team);
      setSelectedTeam(team);
      setTimeout(() => {
        setIsSwitching(false);
        window.location.href = "/";
        return true;
      }, 800);
    } catch (error) {
      console.error("[error]", error);
      addToast(error?.message?.message || error?.message, {
        appearance: "error",
      });
      return false;
    }
  };

  // set team data from localstorage to teamData state
  useEffect(() => {
    if (user) {
      getTeams();
      getSingleTeam();
    }
  }, [user]);

  const teamUser = teamAndUsers?.team_user;

  const value = useMemo(
    () => ({
      teams: teamAndUsers?.team || [],
      getTeams,
      teamData,
      getSingleTeam,
      switchTeam,
      selectedTeam,
      setSelectedTeam,
      fetching,
      teamUser,
      onRowSelectStateChange,
    }),
    [teamData, selectedTeam, fetching, isFetchTeamUsers, teamUser]
  );
  const [isSwitching, setIsSwitching] = useState(false);
  const onCloseModal = async () => {
    setIsSwitching(false);
  };
  return (
    <TeamContext.Provider value={value}>
      <>
        {children}
        <Modal
          open={isSwitching}
          onClose={onCloseModal}
          closeOnEsc={false}
          showCloseIcon={false}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal",
          }}
          styles={{
            modal: {
              width: 390,
              borderRadius: 7,
            },
          }}
          center
        >
          <div role="alert">
            <div className="items-center  flex-col flex justify-center gap-2  h-48 w-96">
              <p className="mb-4 text-gray-500 text-base">Switching team</p>
              <div className="mt-8 flex justify-center items-center mr-16 ">
                <RiUserLine
                  className="iconTeam h-12 w-12 text-gray-500"
                  id="iconTeam1"
                />
                <RiUserLine
                  className="iconTeam h-12 w-12 text-gray-500"
                  id="iconTeam2"
                />
              </div>
            </div>
          </div>
        </Modal>
      </>
    </TeamContext.Provider>
  );
};
