export default {
  input: {
    base: "block w-full text-sm focus:outline-none leading-5 rounded-md px-4 py-3 border-2 transition-all ease-in-out duration-200",
    active: "focus:border-teal-500 border-cool-gray-300",
    checkbox:
      "text-teal-400 form-checkbox border border-gray-700 hover:border-teal-700 focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-offset-0 rounded cursor-pointer w-3 h-3",
  },
  textarea: {
    base: "block w-full text-sm focus:outline-none leading-5 rounded-md px-4 py-3 border-2 transition-all ease-in-out duration-200",
    active: "focus:border-teal-500 border-cool-gray-300",
  },
  button: {
    size: {
      larger: "px-10 py-4 rounded",
      large: "px-5 py-3 rounded",
      regular: "px-4 py-2 rounded text-sm",
      small: "px-3 py-1 rounded text-sm",
    },
    primary: {
      base: "text-white bg-teal-700 border border-transparent hover:text-teal-700 hover:bg-white hover:border-teal-700",
      active: "focus:ring focus:ring-teal-300 bg-teal-700 border-teal-700",
      disabled: "opacity-50 bg-teal-700 border-teal-700 cursor-not-allowed",
    },
    outline: {
      base: "text-teal-600 border-teal-700 hover:bg-teal-600 hover:text-white hover:border-teal-600 border focus:outline-none",
      active:
        "active:bg-transparent hover:border-teal-500 focus:border-teal-500 active:text-teal-500 focus:ring focus:ring-teal-300",
      disabled: "opacity-50 cursor-not-allowed border-teal-600",
    },
  },
  modal: {
    base: "w-full px-6 bg-white rounded-t-lg sm:rounded-lg sm:m-4 sm:max-w-xl",
  },
  modalBody: {
    base: "mb-6 text-sm text-gray-700",
  },
  // ModalFooter
  modalFooter: {
    base: "flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50",
  },
  // ModalHeader
  modalHeader: {
    base: "mt-4 mb-2 text-lg font-semibold text-gray-700",
  },
  backdrop: {
    base: "fixed inset-0 z-40 flex items-start bg-black bg-opacity-50 sm:justify-center overflow-y-auto",
  },
  badge: {
    base: "inline-flex px-2 text-xs font-medium leading-5 rounded-full",
    success: "text-green-700 bg-green-100",
    danger: "text-red-700 bg-red-100",
    warning: "text-orange-700 bg-orange-100",
    neutral: "text-gray-700 bg-gray-100",
    primary: "text-teal-700 bg-teal-100",
  },
  // Select
  select: {
    base: "block w-full text-sm focus:outline-none leading-5 rounded-md px-4 py-3 border-2 transition-all ease-in-out duration-200",
    active: "focus:border-teal-500 border-cool-gray-300",
    select: "leading-5",
    disabled: "cursor-not-allowed opacity-50 bg-gray-300",
    valid:
      "border-green-600 focus:border-green-400 focus:ring focus:ring-green-200",
    invalid:
      "border-red-600 focus:border-red-400 focus:ring focus:ring-red-200",
  },
};
