import gqt from "graphql-tag";

export const GetTeams = gqt`
    query GetTeams {
        team {
            name
            id
        }
    }
`;

export const DeleteTeamByPk = gqt`
    mutation DeleteTeamByPk($id: uuid = "") {
        delete_team_by_pk(id: $id) {
        id
        }
    }
`;

export const DeleteMemberByPk = gqt`
    mutation DeleteMemberByPk($id: uuid = "") {
        delete_team_user_by_pk(id: $id) {
            id
        }
    }  
`;

export const UpdateMemberStatusByPk = gqt`
    mutation UpdateMemberStatusByPk($id: uuid = "", $stat: String = "") {
        update_team_user_by_pk(pk_columns: {id: $id}, _set: {status: $stat}) {
        id
        }
    } 
`;

export const TeamUser = gqt`
    query TeamUser {
        team_user {
            id
            role
            team_id
            user_id
        }
    }
`;

export const GetTeamsAndUsers = gqt`
query GetTeamsAndUsers {
    team {
      name
      id
    }
    team_user {
      id
      role
      team_id
      user_id
    }
  }
  
`;
